const state = {
    months: ['01','02','03','04','05','06','07','08','09','10','11','12'],
    pivot:[]
}

const getters  = {
    pivot(state){
        return state.pivot
    }
}

const actions = {
    processData({_},payload){
        let final = []
        payload.data.forEach((mn) => {
            let result = calculate(mn.group)
            final.push({
                material_number: mn.material_number,
                data: result,
            })
        })
        return final;
    }
}

const mutations = {
    setGoodIssuedPivot(state, payload){
        // PAYYLOAD BERUPA DATA GROUP BY MATERIAL NUMBER DAN TAHUN
        if(!state.pivot.some((val) => val.year == payload.year)){
            state.pivot.push({
                year:payload.year,
                data:[]
            })
        }
        // MENCARI INDEX RESULT PIVOT BERDASARKAN TAHUN
        let indexYear = state.pivot.findIndex((val) => val.year == payload.year)
        // JIKA MENGGUNAKAN PAGINATE BIASA DATA DI KOSONGKAN TERLEBIH DAHULU
        // state.pivot[indexYear].data = []
        // MELAKUKAN LOOPING UNTUK DATA GROUP
        payload.data.forEach((mn) => {
            let result = calculate(mn.group)
            state.pivot[indexYear].data.push({
                material_number: mn.material_number,
                data: result,
            })
        })
    }
}


const calculate = function(data){
    // RESULT UNTUK MENAMPUNG HASIL PERHITUNGAN 
    // HASIL YANG DI HARAPKAN RESULT : [ { MONTH: 01, SUM_PROCESS:10, SUM_QTY:1, RE_GI:1} ]

    let result = [];
    // MELAKUKAN LOOPING UNTUK DATA GROUP MATERIAL NUMBER
    data.forEach((gi) => {
        // SPLIT UNTUK TANGGAL DENGAN FORMAT YYYY-MM-DD SEHINGGA MENDAPATKAN BULAN
        let giMonth = gi.requirement_date.split('-')[1]
        
        // LOOPING UNTUK PROCESS
        state.months.forEach((month) => {
            // JIKA BULAN YANG BERJALAN BELUM ADA DI RESULT MAKA INISIALISASI TERLEBIH DAHULU
            if(!result.some((val) => val.month == month)){
                result.push({
                    month: month,
                    sum_process: 0,
                    sum_qty: 0,
                    re_gi: 0,
                })
            }

            // MENDAPATKAN INDEX UNTUK BULAN PADA VARIABLE RESULT
            let indexMonth = result.findIndex((val) => val.month === month)

            // JIKA BULAN BERJALAN SAMA DENGAN BULAN PADA MATERIAL NUMBER
            if(giMonth === month){
                // MENAMBAHKAN PROCESS (SESUAI JUMLAH MATERIAL NUMBER YANG SAMA BULANNYA)
                result[indexMonth].sum_process =  result[indexMonth].sum_process + 1;
                // MENAMBAHKAN QTY 
                result[indexMonth].sum_qty     =  (gi.type == 'GI') ? result[indexMonth].sum_qty + gi.qty : result[indexMonth].sum_qty;
                // MENAMBAHKAN RE_GI
                result[indexMonth].re_gi       =  (gi.type == 'RE-GI') ? result[indexMonth].re_gi + gi.qty : result[indexMonth].re_gi;
            }
        })
    })
    // MENGEMBALIKAN DENGAN ARRAY RESULT
    return result;
           
}


export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations,
}