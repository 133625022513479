const config = {
    active: 'en',
    languages: {}    
}

const translate = {        
    setDefaultLanguage(id) {
        config.active = id;        
    },
    setDictionary(id, data) {
        config.languages[id] = data;
    },
    getLanguage() {
        return config.active;
    },
    setLanguage(id) {        
        config.active = id;
        localStorage.setItem('language', id);
        location.reload();
    },
    tt(text, parser) {                
        if (config.languages[config.active][text]) {
            text = config.languages[config.active][text];
        } else {
            text = 'Unknown \''+text+'\' index in language '+config.active;
        }
        if (parser) {            
            for (var key in parser) {                
                text = text.replace(':'+key, parser[key]);
            }            
        }        
        return text;
    }
}

export default translate