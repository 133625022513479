<template>
    <i class="fa fa-sort" aria-hidden="true" @click="sort('ASC')" v-if="condition == null"></i>
    <i class="fa fa-sort-up" aria-hidden="true" @click="sort('DESC')" v-else-if="condition == 'ASC'"></i>
    <i class="fa fa-sort-down" aria-hidden="true" @click="sort('ASC')" v-else-if="condition == 'DESC'"></i>
</template>

<script>
    export default {
        name: 'sort-icon',
        props: ['condition','value'],
        methods: {
            sort(type) {
                this.$emit("click", type, this.value);
            }
        }
    };
</script>