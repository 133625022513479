<template>
    <div>
        <div class="text-center">
          <img src="/assets/smartcat/data_is_empty.png" width="500">
          <p><b>Data is Empty</b></p>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'empty'
    };
</script>