const config = {
  // apiUrl: "http://192.168.43.140/smartcat/smartcat-api-pi/public/",
  // storageUrl: "http://192.168.43.140/smartcat/smartcat-api-pi/storage/",

  // apiUrl: "http://localhost/smartcat_pi_new/smartcat-api-pi/public/",
  // storageUrl: "http://localhost/smartcat_pi_new/smartcat-api-pi/storage/",

  // apiUrl: 'https://sicat-qas.pupuk-indonesia.com/smartcat_api/public/',
  // storageUrl: 'https://sicat-qas.pupuk-indonesia.com/smartcat_api/storage/',

  // apiUrl: 'https://toms.pupuk-indonesia.com/smartcat_api/public/',
  // storageUrl: 'https://toms.pupuk-indonesia.com/smartcat_api/storage/',

  apiUrl: "https://smartcatapi.sinergiteknik.co.id/testing/public/",
  storageUrl: "https://smartcatapi.sinergiteknik.co.id/testing/storage/",
};

export default config