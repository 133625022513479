<template>
  <div class="card">
    <div class="card-body text-center">
      <img src="/assets/smartcat/expired.png" width="500" alt="Responsive image" class="img-fluid">
      <p>Your MCR has expired.</p>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'expiredpage'
    };
</script>