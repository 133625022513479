const state = {
  materialMovement: [
    {
      id: 1,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
    },
    {
        id: 2,
        materialNumber: "9900104843",
        order: "20000000018",
        sumOfProcess: 1,
        reservation: 196,
        itemNoStock: 2,
        requirementDate: "2018-01-02",
        movementType: 261,
        materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
        plant: "E53A",
        costCenter: "",
        finalIssue: "",
        goodsRecepient: "",
        requirementQuantity: 1,
        qty: 1,
        unitOfEntry: "EA",
        requirementPriority: 0,
        bulkMaterial: "",
        accountAssigmentReservation: "F",
        requirementType: "AR",
        baseDate: "",
        username: "CONVERSION01",
        accountAssignment: "020000000018",
        asset: "",
        subNumber: "",
        network: "",
        receivingPlant: "",
        receivingStorageLocation: "",
        salesOrderItem: 0,
        salesOrderSchedule: 0,
        noVarSizeItems: 0.0,
        effectMaterialPlanning: "",
    },
    {
    id: 3,
    materialNumber: "9900104843",
    order: "20000000018",
    sumOfProcess: 1,
    reservation: 196,
    itemNoStock: 2,
    requirementDate: "2018-01-02",
    movementType: 261,
    materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
    plant: "E53A",
    costCenter: "",
    finalIssue: "",
    goodsRecepient: "",
    requirementQuantity: 1,
    qty: 1,
    unitOfEntry: "EA",
    requirementPriority: 0,
    bulkMaterial: "",
    accountAssigmentReservation: "F",
    requirementType: "AR",
    baseDate: "",
    username: "CONVERSION01",
    accountAssignment: "020000000018",
    asset: "",
    subNumber: "",
    network: "",
    receivingPlant: "",
    receivingStorageLocation: "",
    salesOrderItem: 0,
    salesOrderSchedule: 0,
    noVarSizeItems: 0.0,
    effectMaterialPlanning: "",
    },
    {
    id: 4,
    materialNumber: "9900104843",
    order: "20000000018",
    sumOfProcess: 1,
    reservation: 196,
    itemNoStock: 2,
    requirementDate: "2018-01-02",
    movementType: 261,
    materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
    plant: "E53A",
    costCenter: "",
    finalIssue: "",
    goodsRecepient: "",
    requirementQuantity: 1,
    qty: 1,
    unitOfEntry: "EA",
    requirementPriority: 0,
    bulkMaterial: "",
    accountAssigmentReservation: "F",
    requirementType: "AR",
    baseDate: "",
    username: "CONVERSION01",
    accountAssignment: "020000000018",
    asset: "",
    subNumber: "",
    network: "",
    receivingPlant: "",
    receivingStorageLocation: "",
    salesOrderItem: 0,
    salesOrderSchedule: 0,
    noVarSizeItems: 0.0,
    effectMaterialPlanning: "",
    },
    {
        id: 1,
        materialNumber: "9900104843",
        order: "20000000018",
        sumOfProcess: 1,
        reservation: 196,
        itemNoStock: 2,
        requirementDate: "2018-01-02",
        movementType: 261,
        materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
        plant: "E53A",
        costCenter: "",
        finalIssue: "",
        goodsRecepient: "",
        requirementQuantity: 1,
        qty: 1,
        unitOfEntry: "EA",
        requirementPriority: 0,
        bulkMaterial: "",
        accountAssigmentReservation: "F",
        requirementType: "AR",
        baseDate: "",
        username: "CONVERSION01",
        accountAssignment: "020000000018",
        asset: "",
        subNumber: "",
        network: "",
        receivingPlant: "",
        receivingStorageLocation: "",
        salesOrderItem: 0,
        salesOrderSchedule: 0,
        noVarSizeItems: 0.0,
        effectMaterialPlanning: "",
      },
      {
          id: 2,
          materialNumber: "9900104843",
          order: "20000000018",
          sumOfProcess: 1,
          reservation: 196,
          itemNoStock: 2,
          requirementDate: "2018-01-02",
          movementType: 261,
          materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
          plant: "E53A",
          costCenter: "",
          finalIssue: "",
          goodsRecepient: "",
          requirementQuantity: 1,
          qty: 1,
          unitOfEntry: "EA",
          requirementPriority: 0,
          bulkMaterial: "",
          accountAssigmentReservation: "F",
          requirementType: "AR",
          baseDate: "",
          username: "CONVERSION01",
          accountAssignment: "020000000018",
          asset: "",
          subNumber: "",
          network: "",
          receivingPlant: "",
          receivingStorageLocation: "",
          salesOrderItem: 0,
          salesOrderSchedule: 0,
          noVarSizeItems: 0.0,
          effectMaterialPlanning: "",
      },
      {
      id: 3,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
      id: 4,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
        id: 1,
        materialNumber: "9900104843",
        order: "20000000018",
        sumOfProcess: 1,
        reservation: 196,
        itemNoStock: 2,
        requirementDate: "2018-01-02",
        movementType: 261,
        materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
        plant: "E53A",
        costCenter: "",
        finalIssue: "",
        goodsRecepient: "",
        requirementQuantity: 1,
        qty: 1,
        unitOfEntry: "EA",
        requirementPriority: 0,
        bulkMaterial: "",
        accountAssigmentReservation: "F",
        requirementType: "AR",
        baseDate: "",
        username: "CONVERSION01",
        accountAssignment: "020000000018",
        asset: "",
        subNumber: "",
        network: "",
        receivingPlant: "",
        receivingStorageLocation: "",
        salesOrderItem: 0,
        salesOrderSchedule: 0,
        noVarSizeItems: 0.0,
        effectMaterialPlanning: "",
      },
      {
          id: 2,
          materialNumber: "9900104843",
          order: "20000000018",
          sumOfProcess: 1,
          reservation: 196,
          itemNoStock: 2,
          requirementDate: "2018-01-02",
          movementType: 261,
          materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
          plant: "E53A",
          costCenter: "",
          finalIssue: "",
          goodsRecepient: "",
          requirementQuantity: 1,
          qty: 1,
          unitOfEntry: "EA",
          requirementPriority: 0,
          bulkMaterial: "",
          accountAssigmentReservation: "F",
          requirementType: "AR",
          baseDate: "",
          username: "CONVERSION01",
          accountAssignment: "020000000018",
          asset: "",
          subNumber: "",
          network: "",
          receivingPlant: "",
          receivingStorageLocation: "",
          salesOrderItem: 0,
          salesOrderSchedule: 0,
          noVarSizeItems: 0.0,
          effectMaterialPlanning: "",
      },
      {
      id: 3,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
      id: 4,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
        id: 1,
        materialNumber: "9900104843",
        order: "20000000018",
        sumOfProcess: 1,
        reservation: 196,
        itemNoStock: 2,
        requirementDate: "2018-01-02",
        movementType: 261,
        materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
        plant: "E53A",
        costCenter: "",
        finalIssue: "",
        goodsRecepient: "",
        requirementQuantity: 1,
        qty: 1,
        unitOfEntry: "EA",
        requirementPriority: 0,
        bulkMaterial: "",
        accountAssigmentReservation: "F",
        requirementType: "AR",
        baseDate: "",
        username: "CONVERSION01",
        accountAssignment: "020000000018",
        asset: "",
        subNumber: "",
        network: "",
        receivingPlant: "",
        receivingStorageLocation: "",
        salesOrderItem: 0,
        salesOrderSchedule: 0,
        noVarSizeItems: 0.0,
        effectMaterialPlanning: "",
      },
      {
          id: 2,
          materialNumber: "9900104843",
          order: "20000000018",
          sumOfProcess: 1,
          reservation: 196,
          itemNoStock: 2,
          requirementDate: "2018-01-02",
          movementType: 261,
          materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
          plant: "E53A",
          costCenter: "",
          finalIssue: "",
          goodsRecepient: "",
          requirementQuantity: 1,
          qty: 1,
          unitOfEntry: "EA",
          requirementPriority: 0,
          bulkMaterial: "",
          accountAssigmentReservation: "F",
          requirementType: "AR",
          baseDate: "",
          username: "CONVERSION01",
          accountAssignment: "020000000018",
          asset: "",
          subNumber: "",
          network: "",
          receivingPlant: "",
          receivingStorageLocation: "",
          salesOrderItem: 0,
          salesOrderSchedule: 0,
          noVarSizeItems: 0.0,
          effectMaterialPlanning: "",
      },
      {
      id: 3,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
      id: 4,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
        id: 1,
        materialNumber: "9900104843",
        order: "20000000018",
        sumOfProcess: 1,
        reservation: 196,
        itemNoStock: 2,
        requirementDate: "2018-01-02",
        movementType: 261,
        materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
        plant: "E53A",
        costCenter: "",
        finalIssue: "",
        goodsRecepient: "",
        requirementQuantity: 1,
        qty: 1,
        unitOfEntry: "EA",
        requirementPriority: 0,
        bulkMaterial: "",
        accountAssigmentReservation: "F",
        requirementType: "AR",
        baseDate: "",
        username: "CONVERSION01",
        accountAssignment: "020000000018",
        asset: "",
        subNumber: "",
        network: "",
        receivingPlant: "",
        receivingStorageLocation: "",
        salesOrderItem: 0,
        salesOrderSchedule: 0,
        noVarSizeItems: 0.0,
        effectMaterialPlanning: "",
      },
      {
          id: 2,
          materialNumber: "9900104843",
          order: "20000000018",
          sumOfProcess: 1,
          reservation: 196,
          itemNoStock: 2,
          requirementDate: "2018-01-02",
          movementType: 261,
          materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
          plant: "E53A",
          costCenter: "",
          finalIssue: "",
          goodsRecepient: "",
          requirementQuantity: 1,
          qty: 1,
          unitOfEntry: "EA",
          requirementPriority: 0,
          bulkMaterial: "",
          accountAssigmentReservation: "F",
          requirementType: "AR",
          baseDate: "",
          username: "CONVERSION01",
          accountAssignment: "020000000018",
          asset: "",
          subNumber: "",
          network: "",
          receivingPlant: "",
          receivingStorageLocation: "",
          salesOrderItem: 0,
          salesOrderSchedule: 0,
          noVarSizeItems: 0.0,
          effectMaterialPlanning: "",
      },
      {
      id: 3,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
      id: 4,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
        id: 1,
        materialNumber: "9900104843",
        order: "20000000018",
        sumOfProcess: 1,
        reservation: 196,
        itemNoStock: 2,
        requirementDate: "2018-01-02",
        movementType: 261,
        materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
        plant: "E53A",
        costCenter: "",
        finalIssue: "",
        goodsRecepient: "",
        requirementQuantity: 1,
        qty: 1,
        unitOfEntry: "EA",
        requirementPriority: 0,
        bulkMaterial: "",
        accountAssigmentReservation: "F",
        requirementType: "AR",
        baseDate: "",
        username: "CONVERSION01",
        accountAssignment: "020000000018",
        asset: "",
        subNumber: "",
        network: "",
        receivingPlant: "",
        receivingStorageLocation: "",
        salesOrderItem: 0,
        salesOrderSchedule: 0,
        noVarSizeItems: 0.0,
        effectMaterialPlanning: "",
      },
      {
          id: 2,
          materialNumber: "9900104843",
          order: "20000000018",
          sumOfProcess: 1,
          reservation: 196,
          itemNoStock: 2,
          requirementDate: "2018-01-02",
          movementType: 261,
          materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
          plant: "E53A",
          costCenter: "",
          finalIssue: "",
          goodsRecepient: "",
          requirementQuantity: 1,
          qty: 1,
          unitOfEntry: "EA",
          requirementPriority: 0,
          bulkMaterial: "",
          accountAssigmentReservation: "F",
          requirementType: "AR",
          baseDate: "",
          username: "CONVERSION01",
          accountAssignment: "020000000018",
          asset: "",
          subNumber: "",
          network: "",
          receivingPlant: "",
          receivingStorageLocation: "",
          salesOrderItem: 0,
          salesOrderSchedule: 0,
          noVarSizeItems: 0.0,
          effectMaterialPlanning: "",
      },
      {
      id: 3,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
      id: 4,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
        id: 1,
        materialNumber: "9900104843",
        order: "20000000018",
        sumOfProcess: 1,
        reservation: 196,
        itemNoStock: 2,
        requirementDate: "2018-01-02",
        movementType: 261,
        materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
        plant: "E53A",
        costCenter: "",
        finalIssue: "",
        goodsRecepient: "",
        requirementQuantity: 1,
        qty: 1,
        unitOfEntry: "EA",
        requirementPriority: 0,
        bulkMaterial: "",
        accountAssigmentReservation: "F",
        requirementType: "AR",
        baseDate: "",
        username: "CONVERSION01",
        accountAssignment: "020000000018",
        asset: "",
        subNumber: "",
        network: "",
        receivingPlant: "",
        receivingStorageLocation: "",
        salesOrderItem: 0,
        salesOrderSchedule: 0,
        noVarSizeItems: 0.0,
        effectMaterialPlanning: "",
      },
      {
          id: 2,
          materialNumber: "9900104843",
          order: "20000000018",
          sumOfProcess: 1,
          reservation: 196,
          itemNoStock: 2,
          requirementDate: "2018-01-02",
          movementType: 261,
          materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
          plant: "E53A",
          costCenter: "",
          finalIssue: "",
          goodsRecepient: "",
          requirementQuantity: 1,
          qty: 1,
          unitOfEntry: "EA",
          requirementPriority: 0,
          bulkMaterial: "",
          accountAssigmentReservation: "F",
          requirementType: "AR",
          baseDate: "",
          username: "CONVERSION01",
          accountAssignment: "020000000018",
          asset: "",
          subNumber: "",
          network: "",
          receivingPlant: "",
          receivingStorageLocation: "",
          salesOrderItem: 0,
          salesOrderSchedule: 0,
          noVarSizeItems: 0.0,
          effectMaterialPlanning: "",
      },
      {
      id: 3,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
      id: 4,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
        id: 1,
        materialNumber: "9900104843",
        order: "20000000018",
        sumOfProcess: 1,
        reservation: 196,
        itemNoStock: 2,
        requirementDate: "2018-01-02",
        movementType: 261,
        materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
        plant: "E53A",
        costCenter: "",
        finalIssue: "",
        goodsRecepient: "",
        requirementQuantity: 1,
        qty: 1,
        unitOfEntry: "EA",
        requirementPriority: 0,
        bulkMaterial: "",
        accountAssigmentReservation: "F",
        requirementType: "AR",
        baseDate: "",
        username: "CONVERSION01",
        accountAssignment: "020000000018",
        asset: "",
        subNumber: "",
        network: "",
        receivingPlant: "",
        receivingStorageLocation: "",
        salesOrderItem: 0,
        salesOrderSchedule: 0,
        noVarSizeItems: 0.0,
        effectMaterialPlanning: "",
      },
      {
          id: 2,
          materialNumber: "9900104843",
          order: "20000000018",
          sumOfProcess: 1,
          reservation: 196,
          itemNoStock: 2,
          requirementDate: "2018-01-02",
          movementType: 261,
          materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
          plant: "E53A",
          costCenter: "",
          finalIssue: "",
          goodsRecepient: "",
          requirementQuantity: 1,
          qty: 1,
          unitOfEntry: "EA",
          requirementPriority: 0,
          bulkMaterial: "",
          accountAssigmentReservation: "F",
          requirementType: "AR",
          baseDate: "",
          username: "CONVERSION01",
          accountAssignment: "020000000018",
          asset: "",
          subNumber: "",
          network: "",
          receivingPlant: "",
          receivingStorageLocation: "",
          salesOrderItem: 0,
          salesOrderSchedule: 0,
          noVarSizeItems: 0.0,
          effectMaterialPlanning: "",
      },
      {
      id: 3,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
      id: 4,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
        id: 1,
        materialNumber: "9900104843",
        order: "20000000018",
        sumOfProcess: 1,
        reservation: 196,
        itemNoStock: 2,
        requirementDate: "2018-01-02",
        movementType: 261,
        materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
        plant: "E53A",
        costCenter: "",
        finalIssue: "",
        goodsRecepient: "",
        requirementQuantity: 1,
        qty: 1,
        unitOfEntry: "EA",
        requirementPriority: 0,
        bulkMaterial: "",
        accountAssigmentReservation: "F",
        requirementType: "AR",
        baseDate: "",
        username: "CONVERSION01",
        accountAssignment: "020000000018",
        asset: "",
        subNumber: "",
        network: "",
        receivingPlant: "",
        receivingStorageLocation: "",
        salesOrderItem: 0,
        salesOrderSchedule: 0,
        noVarSizeItems: 0.0,
        effectMaterialPlanning: "",
      },
      {
          id: 2,
          materialNumber: "9900104843",
          order: "20000000018",
          sumOfProcess: 1,
          reservation: 196,
          itemNoStock: 2,
          requirementDate: "2018-01-02",
          movementType: 261,
          materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
          plant: "E53A",
          costCenter: "",
          finalIssue: "",
          goodsRecepient: "",
          requirementQuantity: 1,
          qty: 1,
          unitOfEntry: "EA",
          requirementPriority: 0,
          bulkMaterial: "",
          accountAssigmentReservation: "F",
          requirementType: "AR",
          baseDate: "",
          username: "CONVERSION01",
          accountAssignment: "020000000018",
          asset: "",
          subNumber: "",
          network: "",
          receivingPlant: "",
          receivingStorageLocation: "",
          salesOrderItem: 0,
          salesOrderSchedule: 0,
          noVarSizeItems: 0.0,
          effectMaterialPlanning: "",
      },
      {
      id: 3,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
      id: 4,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
        id: 1,
        materialNumber: "9900104843",
        order: "20000000018",
        sumOfProcess: 1,
        reservation: 196,
        itemNoStock: 2,
        requirementDate: "2018-01-02",
        movementType: 261,
        materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
        plant: "E53A",
        costCenter: "",
        finalIssue: "",
        goodsRecepient: "",
        requirementQuantity: 1,
        qty: 1,
        unitOfEntry: "EA",
        requirementPriority: 0,
        bulkMaterial: "",
        accountAssigmentReservation: "F",
        requirementType: "AR",
        baseDate: "",
        username: "CONVERSION01",
        accountAssignment: "020000000018",
        asset: "",
        subNumber: "",
        network: "",
        receivingPlant: "",
        receivingStorageLocation: "",
        salesOrderItem: 0,
        salesOrderSchedule: 0,
        noVarSizeItems: 0.0,
        effectMaterialPlanning: "",
      },
      {
          id: 2,
          materialNumber: "9900104843",
          order: "20000000018",
          sumOfProcess: 1,
          reservation: 196,
          itemNoStock: 2,
          requirementDate: "2018-01-02",
          movementType: 261,
          materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
          plant: "E53A",
          costCenter: "",
          finalIssue: "",
          goodsRecepient: "",
          requirementQuantity: 1,
          qty: 1,
          unitOfEntry: "EA",
          requirementPriority: 0,
          bulkMaterial: "",
          accountAssigmentReservation: "F",
          requirementType: "AR",
          baseDate: "",
          username: "CONVERSION01",
          accountAssignment: "020000000018",
          asset: "",
          subNumber: "",
          network: "",
          receivingPlant: "",
          receivingStorageLocation: "",
          salesOrderItem: 0,
          salesOrderSchedule: 0,
          noVarSizeItems: 0.0,
          effectMaterialPlanning: "",
      },
      {
      id: 3,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
      {
      id: 4,
      materialNumber: "9900104843",
      order: "20000000018",
      sumOfProcess: 1,
      reservation: 196,
      itemNoStock: 2,
      requirementDate: "2018-01-02",
      movementType: 261,
      materialDescription: "VALVE: GATE, 1-1/2 IN, 800 LB, SOCKWELD",
      plant: "E53A",
      costCenter: "",
      finalIssue: "",
      goodsRecepient: "",
      requirementQuantity: 1,
      qty: 1,
      unitOfEntry: "EA",
      requirementPriority: 0,
      bulkMaterial: "",
      accountAssigmentReservation: "F",
      requirementType: "AR",
      baseDate: "",
      username: "CONVERSION01",
      accountAssignment: "020000000018",
      asset: "",
      subNumber: "",
      network: "",
      receivingPlant: "",
      receivingStorageLocation: "",
      salesOrderItem: 0,
      salesOrderSchedule: 0,
      noVarSizeItems: 0.0,
      effectMaterialPlanning: "",
      },
  ],
};

const getters = {
    dataMovement(state){
        return state.materialMovement
    }
}

const mutations = {
    setMaterial(state, payload){
        const materialIndex = state.materialMovement.findIndex(material => material.id === payload.id);
        state.materialMovement[materialIndex] = payload;
    },
}

const actions = {
    updateMaterial(context, payload){
        context.commit("setMaterial", payload);
    }
}

export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}