import baseApiUrl from '@/configs/config';

const settings = {
    async maximumSizeAlert(){
        const url = baseApiUrl.apiUrl + '/MaximumFile';
        const token = localStorage.getItem('token')
        const  response= await fetch(url,{
            mode:'cors',
            method:'GET',
            headers: {
                'Authorization': 'Bearer '+token,
            }
          })
        const responseData = await response.json()
        const value = JSON.parse(responseData.data.value)

        const result = {
            size: value.max_file,
            text: 'Max Size ' + value.max_file / 1000000 + ' MB'
        }
        return result;
    }
}

export default settings;