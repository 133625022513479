<template>
   <div class="col- ml-4">
      <a @click="$router.go(-1)">
          <i class="fa fa-arrow-left mr-4" style="cursor: pointer;"></i>
      </a>
    </div>
</template>

<script>
    export default {
        name: 'back',
        data() {
            return {          
 
            }
        },
        // props: ['mcrCode', 'formShow'],
        mounted() {
          // this.tracking(this.$props.mcrCode);
        },
        methods:{

        }
    };
</script>