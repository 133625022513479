import functions from '@/helpers/functions';

const state = {
    analysisData: []
}

const getters = {
    analysisData(state, getters, rootState) {
        let rcsPolicy = rootState.rcsPolicy

        state.analysisData.forEach((v) => calculate(v, rcsPolicy))

        return state.analysisData;
    }
}

const actions = {
    getAnalysisData({commit}, payload) {
        commit('setAnalysisData', payload)
    },
    generateAnalysis({commit}, payload) {
        var data = []
        payload.forEach((v) => {
            data.push({
                materialNumber: v.material_number,
                shortDescription: v.short_description,
                longDescription: v.long_description,
                section: 'DRJS',
                criticality: 'A',
                internalLeadTime: 190,
                externalLeadTime: 48,
                totalLeadTimeDays: 0,
                totalLeadTimeMonths: 0,
                availibility: 'A',
                averageUnitPrice: 3,
                price: 40550,
                currency: 'IDR',
                years: [100,100,100,100,100,100,100,100,100,100,100,100],
                uom: 'EA',
                sumOfQty: 0,
                averageDemand: 0,
                annualUsagePrice: 10000,
                stockOnHand: 4,
                usageValue: 'A',
                abcAnalysis: '',
                kebijakanPersediaan: 'Manual',
                z: 1.28,
                averageLeadTime: 0,
                std: 0,
                stl: 0,
                safetyStockExisting: 5,
                safetyStock: 0,
                ropExisting: 5,
                rop: 0,
                annualDemand: 0,
                k: 0,
                i: 0,
                roq: 100,
                eoq: 0,
                safetyStockValue: 0,
                eoqValue: 0,
                averageInventory: 0,
                peakInventory: 0,
                turnOverDays: 0,
                eoqOptimization: 0,
                maxStockOptimization: 0,
                movingType: 'Fast Moving',
                stockClassExisting: 'Rutin Strategis',
                stockClass: 'Rutin Strategis',
                mrpType: 'V1',
                abcIndicator: '',
                potentialSaving: 0,
                potentialCost: 0,
            })
        })

        commit('setGenerateAnalysis', data)
    }
}

const mutations = {
    setAnalysisData(state, payload) {
        state.analysisData = payload
    },
    setGenerateAnalysis(state, payload) {
        state.analysisData = payload
    }
}

// OTHER FUNCTIONS

const calculate = (v, rcsPolicy) => {
    // Sum of Qty
    let sum = 0
    v.years.forEach((x) => {
        sum += x
    })

    // Concenate Criteria
    let abcCriteria          = v.criticality + v.availibility + v.usageValue
    let filteredServiceLevel = rcsPolicy.serviceLevels.filter((x) => x.criteria === abcCriteria)[0]
    let filteredUsageValues  = rcsPolicy.usageValues.filter((x) => {
        let a = []
        if (v.sumOfQty > x.value) {
            a.push(x)
        }

        return a
    })

    console.log(filteredUsageValues)

    v.totalLeadTimeDays    = (v.internalLeadTime + v.externalLeadTime).toLocaleString('id', {maximumFractionDigits: 2})
    v.totalLeadTimeMonths  = (v.totalLeadTimeDays / 30).toLocaleString('id', {maximumFractionDigits: 2})
    v.price                = functions.setCurrency(v.price).formated
    v.abcAnalysis          = abcCriteria
    v.z                    = v.z.toLocaleString('id', {maximumFractionDigits: 2})
    v.sumOfQty             = sum.toLocaleString('id')
    v.averageDemand        = (functions.setCurrency(v.sumOfQty).replaced / 365).toLocaleString('id', {maximumFractionDigits: 2})
    v.annualUsagePrice     = functions.setCurrency(functions.setCurrency(v.averageDemand).replaced * functions.setCurrency(v.price).replaced * 365).formated
    v.usageValue           = 
    v.averageLeadTime      = (functions.setCurrency(v.totalLeadTimeDays).replaced / functions.setCurrency(v.totalLeadTimeMonths).replaced).toLocaleString('id', {maximumFractionDigits: 2})
    v.std                  = (functions.setCurrency(v.averageDemand).replaced * 25 / 100).toLocaleString('id', {maximumFractionDigits: 2})
    v.stl                  = (functions.setCurrency(v.averageLeadTime).replaced * 25 / 100).toLocaleString('id', {maximumFractionDigits: 2})
    v.safetyStock          = Math.ceil(functions.setCurrency(v.z).replaced * Math.sqrt((functions.setCurrency(v.averageLeadTime).replaced * (functions.setCurrency(v.std).replaced * 2)) + (functions.setCurrency(v.averageDemand).replaced * 2) * (functions.setCurrency(v.stl).replaced * 2)))
    v.rop                  = Math.ceil((functions.setCurrency(v.averageDemand).replaced * functions.setCurrency(v.averageLeadTime).replaced) + v.safetyStock)
    v.annualDemand         = v.sumOfQty
    v.k                    = (filteredServiceLevel) ? functions.setCurrency(filteredServiceLevel.k).formated : functions.setCurrency(0).formated
    v.i                    = (filteredServiceLevel) ? (filteredServiceLevel.i / 100).toLocaleString('id', {maximumFractionDigits: 2}) : 0
    v.eoq                  = Math.ceil(Math.sqrt(2 * (functions.setCurrency(v.annualDemand).replaced * functions.setCurrency(v.k).replaced) / (functions.setCurrency(v.i).replaced * functions.setCurrency(v.price).replaced)))
    v.safetyStockValue     = functions.setCurrency((v.safetyStock * functions.setCurrency(v.price).replaced)).formated
    v.eoqValue             = functions.setCurrency((v.eoq * functions.setCurrency(v.price).replaced)).formated
    v.averageInventory     = functions.setCurrency(functions.setCurrency(v.safetyStockValue).replaced + (functions.setCurrency(v.eoqValue).replaced / 2)).formated
    v.peakInventory        = functions.setCurrency(functions.setCurrency(v.safetyStockValue).replaced + functions.setCurrency(v.eoqValue).replaced).formated
    v.turnOverDays         = Math.ceil(((v.safetyStock + v.eoq) / functions.setCurrency(v.annualDemand).replaced * 365))
    v.eoqOptimization      = (v.eoq / (functions.setCurrency(v.turnOverDays).replaced/364)).toLocaleString('id', {maximumFractionDigits: 2})
    v.maxStockOptimization = (functions.setCurrency(v.eoqOptimization).replaced + v.safetyStock).toLocaleString('id', {maximumFractionDigits: 2})
    v.abcIndicator         = (filteredServiceLevel) ? filteredServiceLevel.indicator : ''
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}