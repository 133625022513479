import Service from "@/helpers/service";

export default {
  get() {
    return Service().get("note");
  },
  create(params) {
    return Service().post("note/create", params);
  },
  update(id, params) {
    return Service().post("note/update/" + id, params);
  },
  delete(id) {
    return Service().post("note/delete/" + id);
  }
};