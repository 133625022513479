const state = {
    operations: [
        {
            id:1,
            operation:'>',
        },
        {
            id:2,
            operation:'<',
        },
        {
            id:3,
            operation:'>=',
        },
        {
            id:4,
            operation:'<=',
        },
        {
            id:5,
            operation:'=='
        },
        {
            id:5,
            operation:'>= & <='
        },
    ],
    criticals: [],
    leadTimes: [],
    usageValues: [],
    serviceLevels: [],
    movingTypes: [],
    timePeriods:[],
    stockClass: [
        {
            type: "Routine",
            operation: ">",
            value: "4",
            description: "Freukensi mutasi > 4 kali/tahun"
        },
        {
            type: "On Demand",
            operation: "<",
            value: "4",
            description: "Freukensi mutasi < 4 kali/tahun "
        },
        {
            type: "Do not Order",
            operation: "==",
            value: "0",
            description: "Tidak ada pemakaian 2 tahun terakhir sejak barang diterima"
        },
        {
            type: "Insurance",
            operation: "==",
            value: "0",
            description: "Lead time relatif lama (diatas 1 bulan)/produk berdasarkan pesanan, penggunaan belum pasti, dan sangat beresiko terhadap kelangsungan operasi"
        },
        {
            type: "Usulan Penghapusan",
            operation: "==",
            value: "0",
            description: "Penghapusan atas materia/barang yang tidak akan digunakan lagi pada masa mendatang"
        },
    ]
}

const getters = {
    
}

const mutations = {
    setPolicies(state, payload){
        let value;
        if(payload.data.length == 0){
            value = []
        }else{
            value = JSON.parse(payload.data.value)
        }
        state[payload.type] = value;
    },
}

const actions = {

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}