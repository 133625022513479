import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import router from './routes/router';
import store from './store'
import InfiniteLoading from 'vue-infinite-loading';
Vue.use(DashboardPlugin);
Vue.use(InfiniteLoading)
/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
});
