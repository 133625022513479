const state = {
  transactionPivot:[],
  months:['01','02','03','04','05','06','07','08','09','10','11','12'],
};

const getters = {
    transactionPivot(state) {
        return state.transactionPivot;
    },
};

const mutations = {
    loadTransaction(state,payload) {
        state.transactionPivot = payload;
    },
    setGoodIssued(state, payload){
      state.goodIssued = payload
    },
};

const actions = {
    loadTransaction({_,commit, rootGetters}) {
        const dataTransaction = rootGetters["rcsTransactionHistory/dataTransaction"];
        const result = [];
        if (dataTransaction.length > 0) {
          for (var key in dataTransaction) {
            const resultOperation = leadTime(dataTransaction[key].requisitionDate, dataTransaction[key].poDate, dataTransaction[key].promiseDate)
            const dataResult = {
              materialNumber: dataTransaction[key].materialNumber,
              sumOfProcess: 1,
              internalLeadTime: resultOperation.internalLeadTime,
              externalLeadTime: resultOperation.externalLeadTime,
              sumOfLeadTime: resultOperation.sumOfLeadTime,
              sumOfPoQty: dataTransaction[key].poQty,
              unitPrice: dataTransaction[key].unitPricePr,
            };
            result.push(dataResult);
          }
        }
        commit('loadTransaction', result)
    },
    setTransactionPivot({state, _}, payload) {
        const pivots = state.transactionPivot;
        const index = pivots.findIndex(el => el.id === payload.id);

        const resultOperation = leadTime(
          payload.requisitionDate,
          payload.poDate,
          payload.promiseDate
        );

        const dataResult = {
          materialNumber: payload.materialNumber,
          sumOfProcess: 1,
          internalLeadTime: resultOperation.internalLeadTime,
          externalLeadTime: resultOperation.externalLeadTime,
          sumOfLeadTime: resultOperation.sumOfLeadTime,
          sumOfPoQty: payload.poQty,
          unitPrice: payload.unitPricePr,
        };
        pivots[index] = dataResult
    },
};

function leadTime(pr, po, pd) {
    const requisitionDate = new Date(pr).getTime();
    const poDate = new Date(po).getTime();
    const pdDate = new Date(pd).getTime();
    const internalLeadTime = Math.abs(poDate - requisitionDate) / (1000 * 3600 * 24);
    const externalLeadTime = Math.abs(poDate - pdDate) / (1000 * 3600 * 24);
    const sumOfLeadTime = internalLeadTime + externalLeadTime;

    const result = {
        requisitionDate: requisitionDate,
        poDate: poDate,
        pdDate: pdDate,
        internalLeadTime: internalLeadTime,
        externalLeadTime: externalLeadTime,
        sumOfLeadTime: sumOfLeadTime
    }

    return result
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
