<template>
   <modal :show.sync="previewAttachmentModalProp" modalContentClasses="bg-transparent border-none shadow-none" size="xl">
        <embed type="application/pdf" :src="attachmentFileProp" frameborder="0" width="100%" height="550px" v-if="['pdf','PDF'].includes(attachmentTypeProp)">
        <div class="text-center" v-else-if="['png','PNG','jpg','JPG','jpeg','JPEG'].includes(attachmentTypeProp)">
            <img :src="attachmentFileProp" width="300">
        </div>

        <template slot="footer">
            <base-button type="secondary" @click="previewAttachmentModalProp = false">{{ tt('close') }}</base-button>
        </template>
    </modal>
</template>
<script>

    export default {
        name: 'preview-attachment',
        data() {
            return {
            }
        },
        props: ['previewAttachmentModalProp', 'attachmentFileProp', 'attachmentTypeProp'],
        watch: {
          previewAttachmentModalProp: function() {
            this.$emit('changeModal', this.previewAttachmentModalProp)
          }
        },
    };
</script>