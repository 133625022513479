<template>
  <div class="main-page" style="overflow:auto">
    <router-view></router-view>
    <notifications></notifications>
  </div>
</template>

<script>
  export default {
    watch: {
      '$route' (to, from) {
        if (to.name) {
          document.title = to.name+' - SmartCat';
        } else {
          document.title = 'SmartCat';
        }
      }
    }
  }
</script>