const functions = {
    fixCursor(e) {
        const sel  = e.target.selectionStart;
        this.$nextTick(() => {
            e.target.setSelectionRange(sel, sel);
        });
    },
    setCurrency(value) { // value harus string
        var formatter = new Intl.NumberFormat('id', {
            style: 'currency',
            currency: 'IDR',
        });

        var replaced = parseFloat(value.toString().replace('Rp', '').replaceAll('.', '').replaceAll(',', '.').trim());
        var formated = formatter.format(replaced);

        return {
            replaced,
            formated
        };
    },
    setClearCurrency(value) {
        var replaced = value.replace('Rp', '').trim();

        return replaced;
    },
}

export default functions;