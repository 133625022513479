<template>
  <div class="custom-file">
    <input type="file"
           class="custom-file-input"
           id="customFileLang"
           lang="en"
           v-bind="$attrs"
           v-on="listeners"
           ref="file"
    />
    <label class="custom-file-label" for="customFileLang">
      {{label}}
    </label>
  </div>
</template>
<script>
  export default {
    name: 'file-input',
    inheritAttrs: false,
    props: {
      initialLabel: {
        type: String,
        default: 'Select file'
      },
      maxFile:{
        type: String,
        default: '0',
      }, 
    },
    data() {
      return {
        files: []
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          change: this.fileChange
        }
      },
      label() {
        let fileNames = [];
        let fileSize = [];
        for (let file of this.files) {
          fileNames.push(file.name);
          fileSize.push(file.size);
        }
        if (fileSize > Number(this.maxFile)) {
          return this.initialLabel
        }else{
          return fileNames.length ? fileNames.join(', ') : this.initialLabel
        }
      }
    },
    methods: {
      fileChange(evt) {
        if (evt == null) {
          this.$refs.file.value = '';
        }else{
          this.files = evt.target.files
          this.$emit('change', this.files)
        }
      }
    }
  }
</script>
<style>
</style>
