import functions from '@/helpers/functions';

const state = {
    data: null
}

const getters = {
    data: state => state.data.slice(0, 10)
}

const actions = {
    getData({commit}, payload) {
        commit('setData', payload)
    },
    formatCurrency({commit}, payload) {
        commit('setCurrency', payload)
    },
    clearCurrency({commit}, payload) {
        commit('setClearCurrency', payload)
    }
}

const mutations = {
    setData(state, data) {
        state.data = data
    },
    setCurrency(state, payload) {
        state.data[payload.key].material_number = functions.setCurrency(payload.value).formated
    },
    setClearCurrency(state, payload) {
        state.data[payload.key].material_number = functions.setClearCurrency(payload.value)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}