<template>
   <modal :show.sync="formShow" size="lg">
        <template slot="header">
              <h5 class="modal-title">{{ tt('approval_tracking') }}</h5>
          </template>
          <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.tracking">
              <el-table-column :label="tt('date')" :prop="tt('date')" min-width="100px" sortable>
                  <template v-slot="{row}">
                      {{ row.created_at }}
                  </template>
              </el-table-column>
              <el-table-column :label="tt('status')" :prop="tt('status')" min-width="100px" sortable>
                  <template v-slot="{row}">
                      {{ row.message }}
                  </template>
              </el-table-column>
              <el-table-column :label="tt('approver')" :prop="tt('approver')" min-width="100px" sortable>
                  <template v-slot="{row}">
                      {{ row.name }}
                  </template>
              </el-table-column>
              <el-table-column :label="tt('message')" :prop="tt('message')" min-width="100px" sortable>
                  <template v-slot="{row}">
                      {{ row.note }}
                  </template>
              </el-table-column>
          </el-table>
    </modal>
</template>
<script>
import { mapState } from "vuex";
import Api from "@/helpers/api";
import draftList from "@/services/material/draftList.service";

    export default {
        name: 'tracking',
        data() {
            return {          
                formTracking: {
                    show: false
                },  
                table: {
                    tracking: []
                },  
            }
        },
        props: ['mcrCode', 'formShow'],
        mounted() {
          this.tracking(this.$props.mcrCode);
        },
        methods:{
          tracking(mcr_code) {
              let context = this;
              Api(context, draftList.log_approval(mcr_code))
              .onSuccess(function(response) {
                context.table.tracking = response.data.data;
              })
              .onError(function(error) {
                if (error.response.status == 404) {
                  context.table.tracking = [];
                }
              })
              .call();
          },
        }
    };
</script>