<template>
    <div>
        <div v-if="authUserPermission['smartcat-attachment-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_attachment')" v-on:keyup="filter">
                                </div>

                                <!-- input untuk nyimpe link url attachment -->
                                <input type="hidden" ref="attachmentLink" v-model="urlAttachment">
                            </div>
                            <div class="col text-right">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['smartcat-attachment-create-folder'] || authUserPermission['smartcat-attachment-create-file']">
                                    <base-button size="sm">{{ tt('add_new') }}</base-button>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item :command="{action:'new_folder'}" v-if="authUserPermission['smartcat-attachment-create-folder']">{{ tt('new_folder') }}</el-dropdown-item>                             
                                        <el-dropdown-item :command="{action:'new_file'}" v-if="authUserPermission['smartcat-attachment-create-file']">{{ tt('new_file') }}</el-dropdown-item>
                                        <el-dropdown-item :command="{action:'new_link'}">{{ tt('new_link') }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <button @click="detail(previousFolder)" v-if="folderId != 0" class="btn btn-outline-primary btn-sm font-weight-bold text-muted mb-2"><i class="fas fa-long-arrow-alt-left"> Back</i></button>
                            </div>
                        </div>
                    </div>

                    <skeleton-loading v-if="requestCalls > 0"></skeleton-loading>

                    <div class="table-responsive" style="height: 500px" v-else>
                        <table class="table table-flush">
                            <!-- HEADER -->
                            <thead class="thead-light">
                                <th>{{ tt('name') }}</th>
                                <th>{{ tt('upload_by')}}</th>
                                <th>{{ tt('created_at') }}</th>
                                <th>{{ tt('size') }}</th>
                                <th width="5"></th>
                            </thead>
                            <!-- LIST FOLDER -->
                            <tbody v-for="(folder) in DataAttachment.data.folder" :key="'folder'+ folder.id">
                                <td @click="detail(folder.folder_attachment_code)">
                                    <span style="cursor: pointer"><i class="fa fa-folder text-warning pr-10 mr-4"></i>{{ folder.folder_name }}</span>
                                </td>
                                <td>{{ folder.created_by }}</td>
                                <td>{{ folder.created_at }}</td>
                                <td></td>
                                <td>
                                    <template>
                                        <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['smartcat-attachment-rename-folder'] || authUserPermission['smartcat-attachment-delete-folder'] || authUserPermission['smartcat-attachment-moveto-folder'] || authUserPermission['smartcat-attachment-update-file'] || authUserPermission['smartcat-attachment-delete-file'] || authUserPermission['smartcat-attachment-moveto-file']">
                                            <span class="btn btn-sm btn-icon-only text-light">
                                                <i class="fas fa-ellipsis-v mt-2"></i>
                                            </span>
                                            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                <el-dropdown-item :command="{action:'rename_folder', id:folder.id, name:folder.folder_name}" v-if="authUserPermission['smartcat-attachment-rename-folder']" >{{ tt('rename') }}</el-dropdown-item>                             
                                                <el-dropdown-item :command="{action:'delete_folder', data:folder.folder_attachment_code}" v-if="authUserPermission['smartcat-attachment-delete-folder']">{{ tt('delete') }}</el-dropdown-item>
                                                <el-dropdown-item :command="{action:'move_to', data:folder.id, type:'folder'}" v-if="authUserPermission['smartcat-attachment-moveto-folder']">{{ tt('move_to') }}</el-dropdown-item>                                        
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </td>
                            </tbody>
                            <!-- LIST FILE -->
                            <tbody v-for="(file) in DataAttachment.data.file" :key="'file' + file.id">
                                <td> 
                                    <i class="fa fa-file mr-4" v-if="file.type_of_file !== 'link'"></i>

                                    <i class="fas fa-globe mr-4" v-else-if="file.type_of_file === 'link'"></i>

                                    <span style="color: #5e72e4; cursor: pointer" @click="previewAttachment(apiUrl+'smartcat_attachment/'+ file.file_attachment_name + '.' + file.type_of_file, file.type_of_file)" v-if="['pdf','PDF','png','PNG','jpg','JPG','jpeg','JPEG'].includes(file.type_of_file)">
                                        {{ file.file_attachment_name }}.{{ file.type_of_file }}
                                    </span>

                                    <a :href="file.file_attachment_name" v-else-if="file.type_of_file == 'link'" target="_blank">
                                        {{ file.file_attachment_name }}
                                    </a>

                                    <a :href="apiUrl+'smartcat_attachment/'+ file.file_attachment_name + '.' + file.type_of_file" target="_blank" v-else>
                                        {{ file.file_attachment_name }}.{{ file.type_of_file }}
                                    </a>
                                </td>
                                <td>{{ file.uploaded_by }}</td>
                                <td>{{ file.created_at }}</td>
                                <td>{{ file.file_size }}KB</td>
                                <td>
                                    <template>
                                        <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['smartcat-attachment-rename-folder'] || authUserPermission['smartcat-attachment-delete-folder'] || authUserPermission['smartcat-attachment-moveto-folder'] || authUserPermission['smartcat-attachment-update-file'] || authUserPermission['smartcat-attachment-delete-file'] || authUserPermission['smartcat-attachment-moveto-file']">
                                            <span class="btn btn-sm btn-icon-only text-light">
                                                <i class="fas fa-ellipsis-v mt-2"></i>
                                            </span>
                                            <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                <el-dropdown-item :command="{action:'rename_file', data:file.id, old_name: file.file_attachment_name, type_of_file: file.type_of_file}" v-if="authUserPermission['smartcat-attachment-update-file']">{{ tt('rename') }}</el-dropdown-item>                             
                                                <el-dropdown-item :command="{action:'delete_file', data:file.file_attachment_code}" v-if="authUserPermission['smartcat-attachment-delete-file']">{{ tt('delete') }}</el-dropdown-item>

                                                <el-dropdown-item :command="{action:'move_to', data:file.id, type:'file'}" v-if="authUserPermission['smartcat-attachment-moveto-file']">{{ tt('move_to') }}</el-dropdown-item>   

                                                <el-dropdown-item :command="{action:'copy_link', data:file.file_attachment_name +'.'+ file.type_of_file, type:file.type_of_file}" v-if="file.type_of_file != 'link'">{{ tt('copy_link') }}</el-dropdown-item>  

                                                <el-dropdown-item :command="{action:'copy_link', data:file.file_attachment_name, type:file.type_of_file}" v-if="file.type_of_file == 'link'">{{ tt('copy_link') }}</el-dropdown-item>  
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </template>
                                </td>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <span class="float-right">{{ DataAttachment.data.folder.length + DataAttachment.data.file.length }} Items</span>
                    </div>
                </div>
            </div>

            <!-- PREVIEW ATTACHMENT -->
            <preview-attachment :previewAttachmentModalProp='previewAttachmentModal' :attachmentFileProp='attachmentFile' :attachmentTypeProp='attachmentFileType' @changeModal="changeModalPrev"/>

            <!-- MODAL INPUT FILE -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formFile.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('upload_files') }}</h5>
                    </template>
                    <div>
                        <div v-if="formFile.add">
                            <label class="form-control-label">{{ tt('file_name') }} <span class="text-danger">*</span></label>
                            <file-input @change="filesChange" ref="file" :initial-label="maxValidation.text" :max-file="maxValidation.size"></file-input>
                        </div>
                        <base-dropdown class="nav-item ml--2 mt-3" tag="li" title-classes="nav-link" title-tag="a" :title="tt('file_name_option')" menu-classes="dropdown-menu-sm dropdown-menu-right py-0 overflow-hidden">
                            <template>                         
                                <div class="list-group list-group-flush">
                                    <a class="list-group-item list-group-item-action" @click="file_name_option('freetext')">
                                        {{ tt('freetext') }}
                                    </a>
                                    <a class="list-group-item list-group-item-action" @click="file_name_option('oem')">
                                        {{ tt('oem') }}
                                    </a>
                                    <a class="list-group-item list-group-item-action" @click="file_name_option('general')">
                                        {{ tt('general') }}
                                    </a>
                                </div>
                            </template>
                        </base-dropdown>
                        <hr class="mt-2">
                        <div v-if="fileNameOption == 'freetext'">
                            <label class="form-control-label">{{ tt('file_name') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('file_name')" :placeholder="tt('file_name')" v-model="smartcatAttachment.file.file_attachment_name" rules="required"></base-input>
                        </div>
                        <div v-else-if="fileNameOption == 'oem'">
                            <label class="form-control-label">{{ tt('equipment_name') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('equipment_name')" :placeholder="tt('equipment_name')" v-model="smartcatAttachment.file.equipment_name" rules="required"></base-input>
                            <label class="form-control-label">{{ tt('manufacture_code') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('manufacture_code')" :placeholder="tt('manufacture_code')" v-model="smartcatAttachment.file.manufacture_code" rules="required"></base-input>
                            <label class="form-control-label">{{ tt('model_or_type') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('model_or_type')" :placeholder="tt('model_or_type')" v-model="smartcatAttachment.file.model_or_type" rules="required"></base-input>
                            <label class="form-control-label">{{ tt('part_number_or_drawing') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('part_number_or_drawing')" :placeholder="tt('part_number_or_drawing')" v-model="smartcatAttachment.file.part_number_or_drawing" rules="required"></base-input>
                        </div>
                        <div v-else>
                            <label class="form-control-label">{{ tt('inc') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('inc')" :placeholder="tt('inc')" v-model="smartcatAttachment.file.inc" rules="required"></base-input>
                            <label class="form-control-label">{{ tt('mfr_code') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('mfr_code')" :placeholder="tt('mfr_code')" v-model="smartcatAttachment.file.mfr_code" rules="required"></base-input>
                            <label class="form-control-label">{{ tt('manref') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('manref')" :placeholder="tt('manref')" v-model="smartcatAttachment.file.manref" rules="required"></base-input>
                        </div>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formFile.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveFile" :disabled="btnSaveFile.onLoading || invalid">
                            <span v-if="btnSaveFile.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span>
                                <span v-if="formFile.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- MODAL INPUT FOLDER -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formFolder.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ formFolder.title }}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="(message, index) in errorMessage.data" :key="index">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('folder_name') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('folder_name')" :placeholder="tt('folder_name')" v-model="smartcatAttachment.folder.folder_name" rules="required"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formFolder.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveFolder" :disabled="btnSaveFolder.onLoading || invalid">
                            <span v-if="btnSaveFolder.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="formFolder.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- MODAL INPUT LINK -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formLink.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ formLink.title }}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="(message, index) in errorMessage.data" :key="index">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('link_name') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('link_name')" :placeholder="tt('link_name')" v-model="smartcatAttachment.link.file_attachment_name" rules="required"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formLink.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveLink" :disabled="btnSaveLink.onLoading || invalid">
                            <span v-if="btnSaveLink.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="formLink.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- MODAL COPY LINK -->
            <modal :show.sync="formCopyLink.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('copy_link') }}</h5>
                </template>
                <div>
                    <div class="input-group">
                      <input type="text" class="form-control" ref="attachmentLink" v-model="urlAttachment">
                      <div class="input-group-append">
                        <button class="btn border" type="button" @click="copy_link">
                            <i class="fa fa-clipboard" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                </div>
            </modal>
            
            <!-- MODAL MOVE TO -->
            <modal :show.sync="formMoveTo">
                <template slot="header">
                      <span v-if="folderIdMove != 0">
                          <i class="fa fa-arrow-left mr-4" style="cursor: pointer;" @click="detailMove(previousFolderMove), getFolderCodeMove = '', bgRow = -1"></i>
                      </span>
                    <h5 class="modal-title">{{ tt('move_to') }}</h5>
                </template>
                <div class="table-responsive" style="height: 350px">
                    <table class="table table-flush">
                        <tbody v-for="(folder, i) in DataAttachmentMove.data.folder" :key="'folder'+ folder.id">
                            <td @click="getFolderCodeMove = folder.folder_attachment_code, clickBgRow(i)" :style="bgRow == i ? 'background-color : #F5DEB3' : null">
                                <span><i class="fa fa-folder text-warning pr-10 mr-4"></i>{{ folder.folder_name }}</span>
                            </td>
                            <td width="50">
                                <i class="fas fa-chevron-right" style="cursor: pointer;" @click="detailMove(folder.folder_attachment_code), getFolderCodeMove = folder.folder_attachment_code, bgRow = -1"></i>
                            </td>
                        </tbody>
                    </table>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="formMoveTo = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="moveTo" :disabled="btnMove.onLoading">
                        <span v-if="btnMove.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            <span>{{ tt('move_here') }}</span>
                        </span>
                    </base-button>
                </template>
            </modal>

        </div>
        <noaccess v-else/>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import Api from '@/helpers/api';
import smartcatAttachment from '@/services/dictionary/smartcatAttachment.service';
import baseApiUrl from '@/configs/config';
export default {
    name: 'SmartCatAttachment',
    data(){
        return {
            requestCalls: 0,
            folderId:0,
            parentCode:0,
            previousFolder:0,
            folderIdMove:0,
            previousFolderMove:0,
            apiUrl: baseApiUrl.storageUrl,
            DataAttachment:{
                data:{
                    folder:[],
                    file:[]
                }
            },
            DataAttachmentMove:{
                data:{
                    folder:[],
                    file:[]
                }
            },
            btnMove: {
                onLoading: false
            },
            btnSaveFolder: {
                onLoading: false
            }, 
            fileNameOption:'freetext',
            table: {
                search:this.$route.query.search ? this.$route.query.search : ''
            },
            btnSaveFile: {
                onLoading: false
            },
            btnSaveLink: {
                onLoading: false
            },
            formFolder: {
                title : this.tt('add_new_folder'),
                show:false
            },
            formFile : {
                title : this.tt('add_new_file'),
                show:false
            },
            formLink : {
                title : this.tt('add_new_link'),
                show:false
            },
            formCopyLink : {
                show:false
            },
            formMoveTo : false,
            smartcatAttachment: {
                folder: {
                    folder_name : null,
                    parent_code : null,
                },
                file: {},
                link:{
                    folder_attachment_code:null,
                    file_attachment_code:null,
                    type_of_file:'link',
                    file_attachment_name:null,
                }
            },
            errorMessage: null,
            renameFile:{
                file: {
                    file_attachment_code:null,
                    old_name:null,
                    type_of_file:null,
                }
            },
            urlAttachment: '',
            previewAttachmentModal: false,
            attachmentFile: '',
            attachmentFileType: '',
            getFolderCodeMove: '',
            getIdMove: '',
            getTypeMove: '',
            bgRow: '',
            maxValidation:{
                size:null,
                text:null
            }
        }
    },
    computed:{
        ...mapState({
            authUserPermission :state => state.auth.userPermission
        }),
    },
    mounted(){
        this.maximumValidation()
        this.get(0);
        this.getDataMove(0);
        this.bgRow = -1;
    },
    methods: {
        get(id){
            let context = this;
            context.requestCalls++;
            Api(context, smartcatAttachment.detail_folder(id, {search: this.table.search})).onSuccess(function(response){
                context.DataAttachment.data.folder = response.data.data.folder;
                context.DataAttachment.data.file = response.data.data.file;
                context.folderId = id;
                context.previousFolder = response.data.data.parent.parent_code;
            }).onFinish(function() {
                context.requestCalls--;
            }).call()
            
        },
        detail(id){
            let context = this;
            context.requestCalls++;
            Api(context, smartcatAttachment.detail_folder(id)).onSuccess(function(response){
                context.DataAttachment.data.folder = response.data.data.folder;
                context.DataAttachment.data.file = response.data.data.file;
                context.folderId = id;
                context.previousFolder = response.data.data.parent.parent_code;
            }).onFinish(function() {
                context.requestCalls--;
            }).call()
            
        },
        filter() {
            if(this.table.search != this.$route.query.search){
                let context = this;
                // context.onLoad = true;
                let pathAttachment = this.$route.path
                if(pathAttachment.includes('/dictionary/smartcat-attachment')){
                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        if(this.table.search == ''){
                            // this.$router.push({path:'/dictionary/smartcat-attachment', query:null})
                            this.$router.replace({query:null})
                        }else{
                            // this.$router.push({ path: '/dictionary/smartcat-attachment', query:{search: this.table.search}})
                            this.$router.replace({query:{search: this.table.search}})
                        }
                        this.get(0)
                    }, 300);
                }else{
                    clearTimeout(this.loadTimeout)
                    this.loadTimeout = setTimeout(() => {
                        this.get(0)
                    },300)
                }
            }
        },
        new_link(){
            this.formLink.add = true;
            this.formLink.title = this.tt('add_new_link');
            this.formLink.show = true
        },
        new_folder(){
            this.formFolder.add = true;
            this.formFolder.title = this.tt('add_new_folder');
            this.formFolder.show = true;
        },
        rename_folder(id, old_name){
            this.formFolder.show = true;
            this.formFolder.title = this.tt('rename_folder');
            this.smartcatAttachment.folder.id = id;
            this.smartcatAttachment.folder.folder_name = old_name;
        },
        delete_folder(id){
            this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                let context = this;
                if(result.value){
                    Api(context, smartcatAttachment.delete_folder(id)).onSuccess(function(response){
                        context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                        });
                        context.detail(context.folderId);
                    }).call();
                }
            })
        },
        new_file(){
            this.formFile.add = true;
            this.formFile.title = this.tt('add_new_file');
            this.formFile.show = true;
        },
        delete_file(id){
            this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                let context = this;
                if(result.value){
                    Api(context, smartcatAttachment.delete_file(id)).onSuccess(function(response){
                        context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                        });
                        context.detail(context.folderId);
                    }).call();
                }
            })
        },
        rename_file(id,old_name,type_of_file){
            if(type_of_file == 'link'){
                this.formLink.title = this.tt('rename_file');
                this.formLink.show = true;
                this.formLink.add = false;
                this.smartcatAttachment.link.file_attachment_name = old_name;
            }else{
                this.formFile.title = this.tt('rename_file');
                this.formFile.show = true;
                this.formFile.add = false;
                this.smartcatAttachment.file.file_attachment_name = old_name;
            }
            this.renameFile.file.file_attachment_code = id;
            this.renameFile.file.old_name = old_name;
            this.renameFile.file.type_of_file = type_of_file;
        },
        handleTableAction(command){
            switch(command.action){
                case 'new_folder' : 
                    this.new_folder(command.data);
                    break;
                case 'new_file' :
                    this.new_file(command.data);
                    break;
                case 'file_name_option':
                    this.file_name_option(command.data);
                    break;
                case 'delete_file':
                    this.delete_file(command.data);
                    break;
                case 'rename_file':
                    this.rename_file(command.data,command.old_name, command.type_of_file);
                    break;
                case 'rename_folder':
                    this.rename_folder(command.id, command.name);
                    break;
                case 'delete_folder':
                    this.delete_folder(command.data);
                    break;
                case 'copy_link':
                    this.formCopyLink.show = true;
                    if(command.type == 'link'){
                        this.urlAttachment = command.data;
                    }else{
                        this.urlAttachment = this.apiUrl + 'smartcat_attachment/' + command.data;
                    }
                    break;
                case 'new_link':
                    this.new_link(command.data);
                    break;
                case 'move_to':
                    this.formMoveTo        = true;
                    this.getFolderCodeMove = '';
                    this.getIdMove         = command.data;
                    this.getTypeMove       = command.type;
                    this.getDataMove(0);
                    break;
                default:
                    break;
            }
        },
        filesChange(files) {
            if(files.length === 0){
                return;
            }

            if (files[0].size > this.maxValidation.size) {
                this.smartcatAttachment.file.file = [];
                this.$notify({
                    message :this.maxValidation.text,
                    type: 'danger',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                });
            } else {
                this.smartcatAttachment.file.file = files[0];
            }
        },
        file_name_option(data) {
            this.fileNameOption = data;
        },
        saveLink(){
            this.btnSaveLink.onloading = true;
            let api = null;
            let context = this;

            if(this.folderId == undefined){
                this.smartcatAttachment.link.folder_attachment_code  = 0;
            }else{
                this.smartcatAttachment.link.folder_attachment_code  = this.folderId;
            }

            let formData = new FormData();
            formData.append("file_attachment_name", this.smartcatAttachment.link.file_attachment_name);
            formData.append("type_of_file",'link');
            formData.append("folder_attachment_code",this.folderId);

            if(this.formLink.add){
                api = Api(context, smartcatAttachment.upload_file(formData));
            }else{
                let context = this;
                let idAttachment = this.renameFile.file.file_attachment_code;
                api = Api(context, smartcatAttachment.rename_file(idAttachment,{ file_attachment_name: context.smartcatAttachment.link.file_attachment_name, old_name: context.renameFile.file.old_name, type_of_file: 'link'}));
            }
            api.onSuccess(function(response) {
                context.$notify({
                    message : response.data.message,
                    type: 'success',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                });
                context.formLink.show = false;
                context.detail(context.folderId);
            }).onError(function(error){
                context.errorMessage = error.response.data;
            }).
            onFinish(function(){
                context.btnSaveLink.onLoading = false;
                context.smartcatAttachment.link = {};
            }).call();

        },
        saveFile() {
            this.btnSaveFile.onloading = true;
            let api = null;
            let context = this;
            let formData = new FormData();
            let data = {};
            if(this.folderId == undefined){
                this.smartcatAttachment.file.folder_attachment_code = 0
            }else{
                this.smartcatAttachment.file.folder_attachment_code = this.folderId
            }

            if(this.fileNameOption == 'freetext'){
                this.smartcatAttachment.file.file_attachment_name = this.smartcatAttachment.file.file_attachment_name;
            }else if (this.fileNameOption == 'oem') {
                    this.smartcatAttachment.file.file_attachment_name = this.smartcatAttachment.file.equipment_name+' - '+this.smartcatAttachment.file.manufacture_code+' - '+this.smartcatAttachment.file.model_or_type+' - '+this.smartcatAttachment.file.part_number_or_drawing;
            } else {
                this.smartcatAttachment.file.file_attachment_name = this.smartcatAttachment.file.inc+' - '+this.smartcatAttachment.file.mfr_code+' - '+this.smartcatAttachment.file.manref;
            }

            if(this.formFile.add){
                formData.append('folder_attachment_code', this.smartcatAttachment.file.folder_attachment_code);
                formData.append('file_attachment_name',this.smartcatAttachment.file.file_attachment_name);
                formData.append('file', this.smartcatAttachment.file.file);
            }else{
                var idAttachment = this.renameFile.file.file_attachment_code;
                data = {
                    file_name_attachment : this.smartcatAttachment.file.file_attachment_name,
                    old_name : this.renameFile.file.old_name,
                    type_of_file : this.renameFile.file.type_of_file
                };
            }

            if(this.formFile.add){
                api = Api(context, smartcatAttachment.upload_file(formData));
            }else{
                let context = this;
                api = Api(context, smartcatAttachment.rename_file(idAttachment,{ file_attachment_name: context.smartcatAttachment.file.file_attachment_name, old_name: context.renameFile.file.old_name, type_of_file: context.renameFile.file.type_of_file}));
            }

            api.onSuccess(function(response){
                context.$notify({
                    message : response.data.message,
                    type: 'success',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                });
                context.formFile.show = false;
                context.detail(context.folderId);
                context.$refs.file.files = '';
                context.$refs.file.fileChange(null); 
                context.btnAdd = true
            }).
            onError(function(error){
                context.errorMessage = error.response.data;
            }).
            onFinish(function(){
                context.btnSaveFile.onLoading = false;
                context.smartcatAttachment.file = {};
                context.smartcatAttachment.file.file = {};
                context.$refs.file.value=null;
            }).call()
        },
        saveFolder(){
            this.btnSaveFolder.onLoading = true;
            let api = null;
            let context = this;

            if(this.folderId == undefined){
                this.smartcatAttachment.folder.parent_code = 0
            }else{
                this.smartcatAttachment.folder.parent_code = this.folderId;
            }

            if(this.formFolder.add){
                api = Api(context, smartcatAttachment.create_folder(this.smartcatAttachment.folder));
            }else{
                api = Api(context, smartcatAttachment.rename_folder(context.smartcatAttachment.folder.id, { folder_name : context.smartcatAttachment.folder.folder_name}));
            }

            api.onSuccess(function(response){
                context.$notify({
                    message : response.data.message,
                    type: 'success',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                });
                context.formFolder.show = false;
                context.detail(context.smartcatAttachment.folder.parent_code);
            }).onError(function(error) {                    
                context.errorMessage = error.response.data;
            }).onFinish(function() {
                context.btnSaveFolder.onLoading = false; 
                context.smartcatAttachment.folder = {};
            })
            .call()
        },
        copy_link() {
            var url = this.$refs.attachmentLink;

            url.select();
    
            document.execCommand("copy");
        },
        previewAttachment(attachment) {
            this.previewAttachmentModal = '';
            this.attachmentFile         = '';
            this.attachmentFileType     = '';
            this.previewAttachmentModal = true;
            this.attachmentFile         = attachment;
            if (attachment.substring(attachment.length-3) == 'peg' || attachment.substring(attachment.length-3) == 'PEG') {
                this.attachmentFileType = attachment.substring(attachment.length-4);
            }else{
                this.attachmentFileType = attachment.substring(attachment.length-3);
            }
        },
        changeModalPrev(value) {
            this.previewAttachmentModal = value;
        },
        getDataMove(id){
            let context = this;
            Api(context, smartcatAttachment.detail_folder(id, {search: this.table.search})).onSuccess(function(response){
                context.DataAttachmentMove.data.folder = response.data.data.folder;
                context.DataAttachmentMove.data.file   = response.data.data.file;
                context.folderIdMove = id;
                context.previousFolderMove = response.data.data.parent.parent_code;
            }).onFinish(function() {
                if (context.folderIdMove == 0) {
                    context.getFolderCodeMove = 0
                }
            }).call()
        },
        detailMove(id){
            let context = this;
            Api(context, smartcatAttachment.detail_folder(id)).onSuccess(function(response){
                context.DataAttachmentMove.data.folder = response.data.data.folder;
                context.DataAttachmentMove.data.file   = response.data.data.file;
                context.folderIdMove = id;
                context.previousFolderMove = response.data.data.parent.parent_code;
            }).onFinish(function() {
                if (context.folderIdMove == 0) {
                    context.getFolderCodeMove = 0
                }
            }).call()
        },
        moveTo(){
            this.confirmDialog(this.tt('confirm_move_file')).then((result) => {
                let context = this;
                if (result.value) {
                    context.btnMove.onLoading = true;
                    let formData = new FormData(); 
                    formData.append('folder_code', (context.getFolderCodeMove == '') ? 0 : context.getFolderCodeMove);
                    formData.append('file_type', context.getTypeMove);

                    Api(context, smartcatAttachment.move_to(context.getIdMove, formData)).onSuccess(function(response) {
                        context.$notify({
                            message: response.data.message,
                            type: 'success',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                        context.detail(context.getFolderCodeMove);
                    }).onError(function(error) {         
                        context.$notify({
                            message: error.response.data.message,
                            type: 'danger',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                    }).onFinish(function() {
                        context.btnMove.onLoading = false;
                        context.formMoveTo        = false
                        context.getIdMove         = '';
                        context.getTypeMove       = '';
                        context.bgRow             = -1;
                    }).call()
                }
            })
        },
        clickBgRow(i){
          this.bgRow = i;
        },
        maximumValidation(){
             this.maximumSizeAlert().then((res) =>{
                 this.maxValidation.size = res.size
                 this.maxValidation.text = res.text
             })
        }
    }
};
</script>