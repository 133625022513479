import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth.js';
import rcsPolicy from './rcsPolicy.js';
import rcsMaterialMovement from './rcsMaterialMovement';
import rcsTransactionHistory from './rcsTransactionHistory';
import rcsTransactionHistoryPivot from './rcsTransactionHistoryPivot';
import rcsAnalysis from './rcsAnalysis';
import rcsGoodIssuedPivot from './rcsGoodIssuedPivot';
import rcsSummary from './rcsSummary';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        rcsPolicy,
        rcsMaterialMovement,
        rcsTransactionHistory,
        rcsTransactionHistoryPivot,
        rcsAnalysis,
        rcsGoodIssuedPivot,
        rcsSummary
    }
});