import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const publicPages = ['Login', 'Forgot Password', 'Reset Password'];
  const authRequired = !publicPages.includes(to.name);
  const authenticated = localStorage.getItem('auth');  
  if (authRequired && !authenticated) {        
      return next({
          name: 'Login',
          query: {redirect: to.fullPath}
      });
  }
  if (authenticated) {
      const auth = JSON.parse(authenticated);
      if (to.name == 'Login') {
          return next({
              name: 'dashboard'
          });
      }
      if (to.name != 'Relogin') {
          if (auth.expired) {
              return next({
                  name: 'relogin'
              });
          }
      }
  }

  next();
})

export default router;
