<template>
    <div class="wrapper">
        <notifications></notifications>
        <side-bar>
            <template slot="links">
                <sidebar-item :link="{name: 'My Profile', icon: 'fa fa-user text-primary', path: '/user/profile'}"></sidebar-item>
                <sidebar-item :link="{name: 'Change Password', icon: 'fa fa-key text-yellow', path: '/user/change-password'}" v-if="created_by == 'SMARTCAT'"></sidebar-item>
            </template>
        </side-bar>
        <div class="main-content">
            <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
            <div @click="$sidebar.displaySidebar(false)">
                <fade-transition :duration="200" origin="center top" mode="out-in">          
                <router-view></router-view>
                </fade-transition>
            </div>
            <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
        </div>
    </div>
</template>
<script>  
    import PerfectScrollbar from 'perfect-scrollbar';
    import 'perfect-scrollbar/css/perfect-scrollbar.css';

    function hasElement(className) {
        return document.getElementsByClassName(className).length > 0;
    }

    function initScrollbar(className) {
        if (hasElement(className)) {
            new PerfectScrollbar(`.${className}`);
        } else {      
            setTimeout(() => {
                initScrollbar(className);
            }, 100);
        }
    }

    import DashboardNavbar from './DashboardNavbar.vue';
    import ContentFooter from './ContentFooter.vue';
    import { FadeTransition } from 'vue2-transitions';
    import Api from '@/helpers/api';
    import userProfile from '@/services/userProfile/userProfile.service';

    export default {
        components: {
            DashboardNavbar,
            ContentFooter,
            FadeTransition
        },
        data(){
            return {
                created_by: '',
            }
        },
        mounted() {
            this.initScrollbar()
            this.get()
        },
        methods: {
            initScrollbar() {
                let isWindows = navigator.platform.startsWith('Win');
                if (isWindows) {
                    initScrollbar('sidenav');
                }
            },
            get() { 
                let context = this;               
                Api(context, userProfile.index()).onSuccess(function(response) {
                    context.created_by = response.data.data.user.user.created_by;
                }).call()
            },
        },
    };
</script>
<style lang="scss">
</style>
