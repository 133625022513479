const state = {
  transactionHistory: [
    {
      id: 1,
      materialNumber: "12345",
      shortDescription: "test",
      longDescription: "test",
      plant: "EA3N",
      site: "DRJ",
      manufacturer: "test",
      purchaseRequisition: "1100014347",
      requisitionDate: "2020-03-13",
      purchaseOrder: "",
      poDate: "2020-03-24",
      promiseDate: "2020-04-10",
      vendor: "test",
      unitPricePr: 450000,
      totalValuePr: 13500000,
      currencyPr: "IDR",
      unitPricePo: 395000,
      totalValuePo: 11850000,
      currencyPo:"IDR",
      poQty: 30,
      uom: "test",
    },
  ],
  transactionHistoryPivot: [],
};

const getters = {
    dataTransaction(state){
        return state.transactionHistory
    }
}

const mutations = {
    update(state, payload){
        const indexData = state.transactionHistory.findIndex(transaction => transaction.id === payload.id)
        state.transactionHistory[indexData] = payload
    }
}

const actions = {
    update(context, payload){
        context.commit('update', payload)
    }
}

export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}